import React from "react"
import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import { FluidImage } from '@atoms/Image'
import Seo from "@atoms/Seo"
import Hero from "@blocks/page/Hero"
import { BreadcrumbList } from "@projects/BreadcrumbList"
import { Section, Title, Paragraph, Column } from "@blocks/page/Section"
import { ContentFooter } from "@projects/ContentFooter"

import css from "@css/projects/page/p-content.module.styl"

export default function About() {
	const q = useStaticQuery(graphql`
		query {
            main01 : file(relativePath: { eq: "pageVision__img__hero@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
			message : file(relativePath: { eq: "pageVision__img__message@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
        }
    `)
	return (
		<>
			<Seo subtitle={'ABOUT'}/>
			<Hero
				image={q.main01}
				videoId="c3t24_u0frQ"
				label='大泉グループを知る'
				title1='DRAW THE FUTURE.'
				title2='地域の未来をつくる。'
				links={[
					{
						label: '代表メッセージ',
						scroll: '#message'
					}, {
						label: '経営理念',
						scroll: '#philosophy'
					},
				]}
			/>
			<div className={css.container}>
				<div className={css.inner}>
					<BreadcrumbList title="大泉グループを知る"/>
					<Section id="message">
						<Title title1='MESSAGE' title2='代表メッセージ'/>
						<Column type="About">
							<div>
								<Paragraph>
									<h2>
										Draw the Future. <br/>
										地域の未来をつくる。
									</h2>
									<p>
										私たちのまち「六ヶ所村」は、人、自然、文化、産業が輝く「共生のまち」を目指しています。
										そこで大泉グループは「地域発展と未来へのまちづくり」をコンセプトとし、「原子力とともに未来への共生」を目指し活動しております。
									</p>
									<p>
										私たちは、経営の基本理念「情報及び技術力の共有を図り、良い仕事をして、発注者と地域社会の信頼を得る」に基づき活動しております。
									</p>
									<p>
										大泉グループでは、積極的に情報や技術を共有しております。それは社員一人ひとりの技術力の向上、成長に繋がります。
										社員一人ひとりの能力が上がれば、それは現場の安全性や仕事の品質向上に繋がっていきます。
										そして、良い仕事ができればお客様や地域との信頼関係を気づくことができ、また良い仕事へと繋がっていきます。
									</p>
									<p>
										その循環が地域の価値創造と持続的発展に寄与すると考えています。<br/>
										それは「より良い地域の未来へ」と繋がると信じています。
									</p>
									<p>
										代表取締役 小泉 國雄
									</p>
								</Paragraph>
							</div>
							<div>
								<figure className={css.image}>
									<FluidImage data={q.message}/>
								</figure>
							</div>
						</Column>
					</Section>
					<Section id="philosophy">
						<Title title1='Philosophy' title2='経営理念' />
						<Paragraph>
							<section>
								<h2>1.情報、技術力の共有</h2>
								<p>
									情報や技術力の共有を積極的に行い、会社や社員の成長を促し、現場の安全性を高めて高品質の仕事を行う。
								</p>
							</section>
							<section>
								<h2>2.良い仕事</h2>
								<p>
									施工業務の計画・設計・調達・施工管理・保守サービスの全領域にわたり、高品質の仕事し、人と自然が調和する環境の創造と環境負荷の低減に取り組む。
								</p>
							</section>
							<section>
								<h2>3.発注者と地域社会の信頼</h2>
								<p>
									「情報、技術力の共有」と「高品質の仕事」を行い、信頼感・安心感・満足感のもてる品質（施工）を提供し、価値創造と持続的発展に寄与する。
								</p>
							</section>
						</Paragraph>
					</Section>
				</div>
			</div>
			<ContentFooter
				banner={{
					img: q.main01,
					label: 'CSR活動',
					title: '大泉グループの取り組み',
					to: '/csr/',
				}}
			/>
		</>
	)
}